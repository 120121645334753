import { Heading } from "@chakra-ui/react";
import { DefaultLayout } from "features/layouts/default-layout/DefaultLayout";
import { PortableText } from "features/portable-text/PortableText";

type ContentPageProps = {
  title: string;
  excerpt: string;
  content?: any;
  tags: string[];
  slugs: { no: string; en: string };
};
export const ContentPage = ({
  title,
  excerpt,
  content,
  tags,
  slugs,
}: ContentPageProps) => {
  return (
    <DefaultLayout
      title={title}
      description={excerpt}
      keywords={tags}
      slugs={slugs}
    >
      <Heading
        fontSize={["3xl", "5xl"]}
        marginTop={10}
        textAlign={["left", "center"]}
      >
        {title}
      </Heading>
      <PortableText value={content} />
    </DefaultLayout>
  );
};
