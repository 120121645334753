import { Container, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import { SupportersLogos } from "features/supporters-logos/SupportersLogos";
import { createTexts, useTranslation } from "utils/i18n";

export const PartnersSection = () => {
  const { t } = useTranslation();
  return (
    <Flex
      paddingY={[6, 12]}
      minHeight="50vh"
      flexDirection="column"
      justify="center"
    >
      <Container marginX="auto" marginBottom={10}>
        <Stack textAlign="center">
          <Heading as="h2" fontSize="5xl">
            {t(texts.heading)}
          </Heading>
          <Text fontSize="xl">{t(texts.description)}</Text>
        </Stack>
      </Container>
      <SupportersLogos />
    </Flex>
  );
};
const texts = createTexts({
  heading: {
    no: "Samarbeidspartnere",
    en: "Partners",
  },
  description: {
    no: (
      <>
        OPRA jobber kontinuerlig med å skaffe nye samarbeidspartnere.
        <br />
        Her er noen av de nyeste.
      </>
    ),
    en: (
      <>
        OPRA is continuously working on getting new partners.
        <br />
        Here are some of the the most recent ones.
      </>
    ),
  },
});
