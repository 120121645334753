import {
  Box,
  Button,
  Center,
  Container,
  DarkMode,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { DefaultHead } from "features/default-head/DefaultHead";
import { TextLink } from "features/design-system/TextLink";
import { LogoSpear } from "features/design-system/logo/LogoSpear";
import { OrganizationSignupForm } from "features/organizations/OrganizationSignupForm";
import { PartnersSection } from "features/organizations/shared/PartnersSection";
import { PortableText } from "features/portable-text/PortableText";
import { SiteFooter } from "features/site-footer/SiteFooter";
import Link from "next/link";
import { SanityImage } from "types/domain";
import { AuthProvider } from "utils/auth/auth-utils";
import { createTexts, useTranslation } from "utils/i18n";
import { urlFor } from "utils/sanity";

type CustomSignupFlowProps = {
  data: {
    organizationName: string;
    shortenedOrganizationName?: string;
    slug: { current: string };
    logo: SanityImage;
    oneliner: any;
    content: any;
    acceptedDomains: string[];
  };
};
export function CustomSignupFlow({ data }: CustomSignupFlowProps) {
  return (
    <AuthProvider>
      <DefaultHead
        title={`OPRA x ${
          data.shortenedOrganizationName ?? data.organizationName
        }`}
        description={`Få gratis tilgang til OPRA gjennom ditt medlemskap i ${data.organizationName}`}
      />
      <Hero {...data} />
      <WhatsThis {...data} />
      <Cta {...data} />
      <PartnersSection />
      <Purpose />
      <SiteFooter />
    </AuthProvider>
  );
}

const Hero = ({ logo, organizationName }: CustomSignupFlowProps["data"]) => {
  const { t } = useTranslation();
  const texts = createTexts({
    intro: {
      no: (
        <>
          <strong>{organizationName}</strong> samarbeider med{" "}
          <strong>Operativ&nbsp;Rabatt</strong> for å gi deg gratis tilgang til
          noen av Norges beste rabatter.
        </>
      ),
      en: (
        <>
          <strong>{organizationName}</strong> collaborates with{" "}
          <strong>Operativ&nbsp;Rabatt</strong> to give you free access to some
          of Norway&apos;s best discounts.
        </>
      ),
    },
    getAccess: {
      no: "Få tilgang nå",
      en: "Get access now",
    },
  });
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      minHeight={["90vh", "75vh"]}
      backgroundImage={`radial-gradient(circle, rgba(0,0,0,0.4), rgba(0,0,0,0.1),transparent), url(/images/get-to-the-choppa-bw.jpg)`}
      backgroundPosition={["80% 60%", "60% 80%"]}
      backgroundRepeat="no-repeat"
      backgroundSize={["auto 140%", "cover"]}
      color="white"
    >
      <Container maxWidth="580px" textAlign="center" paddingY={12}>
        <Stack spacing={6}>
          <Flex direction="row" gap={6} align="center" justify="center">
            <Image
              maxHeight="160px"
              maxWidth="300px"
              src={urlFor(logo).width(600).url()}
              alt={`${organizationName} logo`}
            />
            <Box fontSize="4xl" fontWeight="bold" aria-label="og">
              x
            </Box>
            <LogoSpear maxWidth="160px" />
          </Flex>
          <Text
            fontSize={["lg", "xl"]}
            borderRadius="lg"
            textShadow="0 0 20px black"
          >
            {t(texts.intro)}
          </Text>
          <Box>
            <Button
              variant="solid"
              colorScheme="yellow"
              onClick={(e) => {
                // Attempt to fix a bug where the link links to 404 page
                e.preventDefault();
                document
                  .getElementById("registrer-deg")
                  ?.scrollIntoView({ behavior: "smooth" });
              }}
              size="lg"
            >
              {t(texts.getAccess)}
            </Button>
          </Box>
        </Stack>
      </Container>
    </Flex>
  );
};

const WhatsThis = ({
  organizationName,
  shortenedOrganizationName,
  oneliner,
  content,
}: CustomSignupFlowProps["data"]) => {
  const { t } = useTranslation();
  const texts = createTexts({
    alreadyRegistered: {
      no: (
        <>
          <strong>Er du allerede medlem i OPRA?</strong> Fantastisk! Da er det
          bare å logge inn med eposten din og passordet ditt under.
        </>
      ),
      en: (
        <>
          <strong>Are you already a member in OPRA?</strong> Great! Just sign in
          with your email and password below.
        </>
      ),
    },
  });
  return (
    <Flex paddingY={[12, 16]} flexDirection="column" justifyContent="center">
      <Container maxWidth="xl">
        <Stack textAlign="center">
          <Heading as="h1" fontSize={["3xl", "5xl"]}>
            OPRA x {shortenedOrganizationName ?? organizationName}
          </Heading>
          <Text fontSize="2xl">{oneliner}</Text>
        </Stack>

        <Stack marginTop={[6, 10]} spacing={6} fontSize="lg">
          <PortableText value={content} />
          <Text>{t(texts.alreadyRegistered)}</Text>
        </Stack>
      </Container>
    </Flex>
  );
};

const Cta = ({
  organizationName,
  shortenedOrganizationName,
}: CustomSignupFlowProps["data"]) => {
  return (
    <DarkMode>
      <Box
        marginTop={[6, 12]}
        marginX="auto"
        paddingY={12}
        backgroundColor="brand.slateBlack"
        color="brand.pureWhite"
        id="registrer-deg"
      >
        <OrganizationSignupForm
          organizationName={organizationName}
          shortenedOrganizationName={shortenedOrganizationName}
        />
      </Box>
    </DarkMode>
  );
};

const Purpose = () => {
  const { t } = useTranslation();
  const texts = createTexts({
    heading: {
      no: "Av operative. For operative.",
      en: "By operatives. For operatives.",
    },
    description: {
      no: (
        <>
          OPRA-teamet består av fast ansatte i det operative. Har du spørsmål,{" "}
          <TextLink href="/kontakt">ta kontakt</TextLink> med teamet.
        </>
      ),
      en: (
        <>
          The OPRA team consists of operatives. If you have any questions,{" "}
          <TextLink href="/contact">contact the team</TextLink>.
        </>
      ),
    },
    readMore: {
      link: {
        no: "/om",
        en: "/about",
      },
      text: {
        no: "Les mer om OPRA",
        en: "Read more about OPRA",
      },
    },
    imageAlt: {
      no: "Politi",
      en: "Police",
    },
  });
  return (
    <SimpleGrid columns={[1, 2]} gap={4}>
      <Center>
        <Image
          height={["30vh"]}
          width="100%"
          objectFit="cover"
          objectPosition="50% 50%"
          src="/images/politi-zoom.jpg"
          display={["block", "none"]}
          alt={t(texts.imageAlt)}
        />
        <Image
          height={["100%"]}
          objectFit="cover"
          objectPosition="50% 50%"
          src="/images/politi.jpg"
          display={["none", "block"]}
          alt={t(texts.imageAlt)}
        />
      </Center>
      <Center minHeight="50vh" paddingTop={12}>
        <Container>
          <Stack>
            <Heading as="h2">{t(texts.heading)}</Heading>
            <Text fontSize="lg">{t(texts.description)}</Text>
            <Box paddingTop={6}>
              <Button
                as={Link}
                href={t(texts.readMore.link)}
                variant="solid"
                colorScheme="yellow"
                size="lg"
              >
                {t(texts.readMore.text)}
              </Button>
            </Box>
          </Stack>
        </Container>
      </Center>
    </SimpleGrid>
  );
};
