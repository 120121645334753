import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/browser";
import * as validateEmail from "email-validator";
import { trackEvent } from "features/analytics/analytics";
import { Input } from "features/design-system/Input";
import { PasswordInput } from "features/design-system/PasswordInput";
import { TextLink } from "features/design-system/TextLink";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  FieldValue,
  doc,
  getDoc,
  increment,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useRouter } from "next/router";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { OpraUser } from "types/domain";
import { auth, firestore } from "utils/firebase/client";
import { createTexts, useTranslation } from "utils/i18n";

type OrganizationSignupFormProps = {
  organizationName: string;
  shortenedOrganizationName?: string;
};
export const OrganizationSignupForm = ({
  organizationName,
  shortenedOrganizationName,
}: OrganizationSignupFormProps) => {
  const Organization = (
    shortenedOrganizationName ?? organizationName
  ).toUpperCase();
  const { t } = useTranslation();
  const router = useRouter();
  const { register, formState, handleSubmit, setError, getValues } =
    useForm<FormFields>({
      defaultValues: {
        newOrExistingUser: "new",
        email: "",
        password: "",
        organization: Organization,
      },
    });

  const createToast = useToast();
  const [isLoading, setLoading] = useState(false);

  const onSubmit = handleSubmit(async (formFields: FormFields) => {
    setLoading(true);
    try {
      let uid = await loginOrCreateOrganizationUser(formFields);
      if (!uid) {
        createToast({
          status: "error",
          title: t(texts.form.errors.generic.title),
          description: t(texts.form.errors.generic.message),
        });
        return;
      }

      trackEvent("signup-completed", { organization: Organization });
      router.push(`/oversikt?velkommen`);
    } catch (e: any) {
      switch (e.code) {
        case "auth/invalid-email": {
          setError("email", {
            message: t(texts.form.email.validation.invalid),
          });
          break;
        }
        case "auth/weak-password": {
          setError("password", {
            message: t(texts.form.password.validation.tooWeak),
          });
          break;
        }
        case "auth/invalid-credentials":
        case "auth/wrong-password": {
          setError("email", {
            message: t(texts.form.email.validation.wrongCredentials),
          });
          break;
        }

        default: {
          Sentry.captureException(e);
          setError("email", {
            message: t(texts.form.email.validation.generic),
          });
        }
      }
    } finally {
      setLoading(false);
    }
  });

  const newOrExistingUser = getValues("newOrExistingUser");

  return (
    <Container as="form" onSubmit={onSubmit}>
      <input type="hidden" {...register("organization")} />
      <Stack>
        <Heading as="h2" fontSize="3xl">
          {t(texts.heading)}
        </Heading>
        <Text fontSize="lg">{t(texts.alreadyRegistered)}</Text>
      </Stack>
      <Stack marginTop={[3, 6]}>
        <FormControl
          as="fieldset"
          isInvalid={Boolean(formState.errors.newOrExistingUser)}
        >
          <Box as="legend" fontWeight={500} fontSize="md" marginBottom={2}>
            {t(texts.form.newOrExistingUser.legend)}
          </Box>
          <RadioGroup
            defaultValue="new"
            display="flex"
            flexDirection={["column", "row"]}
            gap={3}
          >
            <Radio
              value="new"
              {...register("newOrExistingUser", {
                required: t(texts.form.newOrExistingUser.validation.required),
              })}
            >
              {t(texts.form.newOrExistingUser.labels.notRegistered)}
            </Radio>
            <Radio
              value="existing"
              {...register("newOrExistingUser", {
                required: t(texts.form.newOrExistingUser.validation.required),
              })}
            >
              {t(texts.form.newOrExistingUser.labels.alreadyRegistered)}
            </Radio>
          </RadioGroup>
        </FormControl>
        <FormControl id="email" isInvalid={Boolean(formState.errors.email)}>
          <FormLabel>{t(texts.form.email.label)}</FormLabel>
          <Input
            type="email"
            {...register("email", {
              required: t(texts.form.email.validation.required),
              validate: (value) =>
                validateEmail.validate(value) ||
                !value.endsWith(".con") ||
                t(texts.form.email.validation.invalid),
            })}
            autoComplete="username"
          />
          <FormErrorMessage>{formState.errors.email?.message}</FormErrorMessage>
          <FormHelperText color="brand.foggyWhite">
            {t(texts.form.email.helperText)}
          </FormHelperText>
        </FormControl>
        <FormControl
          id="password"
          isInvalid={Boolean(formState.errors.password)}
        >
          <FormLabel>{t(texts.form.password.label)}</FormLabel>
          <PasswordInput
            {...register("password", {
              required: t(texts.form.password.validation.required),
              minLength: {
                value: 6,
                message: t(texts.form.password.validation.tooShort),
              },
            })}
            autoComplete={
              newOrExistingUser === "new" ? "new-password" : "current-password"
            }
          />
          <FormErrorMessage>
            {formState.errors.password?.message}
          </FormErrorMessage>
          <FormHelperText color="brand.foggyWhite">
            {t(texts.form.password.helperText)}
          </FormHelperText>
        </FormControl>
        <Stack>
          <FormControl isInvalid={Boolean(formState.errors.acceptsTermsOfUse)}>
            <Checkbox
              {...register("acceptsTermsOfUse", {
                required: t(
                  texts.form.acceptTermsOfUse.validation.required(
                    organizationName
                  )
                ),
              })}
            >
              {t(texts.form.acceptTermsOfUse.label(Organization))}
            </Checkbox>
            <FormErrorMessage>
              {formState.errors.acceptsTermsOfUse?.message}
            </FormErrorMessage>
          </FormControl>
          <Checkbox {...register("acceptsMarketing")}>
            {t(texts.form.acceptMarketing.label)}
          </Checkbox>
        </Stack>
      </Stack>
      <Flex marginTop={[3, 6]} justifyContent="flex-end">
        <Button
          variant="solid"
          colorScheme="yellow"
          type="submit"
          width={["100%", "auto"]}
          isLoading={isLoading}
        >
          {t(texts.form.submit)}
        </Button>
      </Flex>
    </Container>
  );
};

const texts = createTexts({
  heading: {
    no: "Få gratis tilgang",
    en: "Get free access",
  },
  alreadyRegistered: {
    no: "Har du tilgang fra før av, kan du logge inn istedenfor.",
    en: "If you already have access, you can log in instead.",
  },
  form: {
    newOrExistingUser: {
      legend: {
        no: "Har du allerede en bruker på OPRA.no?",
        en: "Do you already have a user on OPRA.no?",
      },
      labels: {
        alreadyRegistered: {
          no: "Ja, jeg har allerede en bruker",
          en: "Yes, I already have a user",
        },
        notRegistered: {
          no: "Nei, jeg har ikke en bruker",
          en: "No, I don't have a user",
        },
      },
      validation: {
        required: {
          no: "Vennligst velg om du er registrert fra før av.",
          en: "Please select if you are already registered.",
        },
      },
    },
    email: {
      label: {
        no: "E-post",
        en: "Email",
      },
      helperText: {
        no: "Bruk privat e-post for å sikre anonymitet.",
        en: "Use your personal email to ensure anonymity.",
      },

      validation: {
        required: {
          no: "Vennligst fyll ut en epost-adresse.",
          en: "Please fill out an email address.",
        },
        invalid: {
          no: "Vennligst fyll ut en gyldig epost-adresse.",
          en: "Please fill out a valid email address.",
        },
        wrongCredentials: {
          no: "Kombinasjonen av e-post og passord er feil.",
          en: "The combination of email and password is wrong.",
        },
        generic: {
          no: "Ukjent feil",
          en: "Unknown error",
        },
      },
    },
    password: {
      label: {
        no: "Passord",
        en: "Password",
      },
      helperText: {
        no: "Passordet må være minst 7 tegn langt. Ikke gjenbruk passord på tvers av tjenester.",
        en: "The password must be at least 7 characters long. Do not reuse passwords across services.",
      },
      validation: {
        required: {
          no: "Vennligst fyll ut et passord",
          en: "Please fill out a password",
        },
        tooShort: {
          no: "Passordet må være minst 7 tegn",
          en: "The password must be at least 7 characters long",
        },
        tooWeak: {
          no: "For svakt passord. Kom på noe som er vanskeligere å gjette.",
          en: "Too weak password. Come up with something harder to guess.",
        },
      },
    },
    acceptTermsOfUse: {
      label: (organization) => ({
        no: (
          <>
            Jeg godtar{" "}
            <TextLink href="/vilkar" target="_blank">
              bruksvilkårene
            </TextLink>{" "}
            til OPRA, og bekrefter at jeg er medlem i {organization}
          </>
        ),
        en: (
          <>
            I accept the{" "}
            <TextLink href="/terms-of-service" target="_blank">
              terms of service
            </TextLink>{" "}
            of OPRA, and confirm that I am a member of {organization}
          </>
        ),
      }),
      validation: {
        required: (organization) => ({
          no: `Du må godta bruksvilkårene til OPRA, og bekrefte at du er medlem i ${organization}`,
          en: `You must accept the terms of use of OPRA, and confirm that you are a member of ${organization}`,
        }),
      },
    },
    acceptMarketing: {
      label: {
        no: "Jeg ønsker å motta sjeldne nyhetsbrev med eksklusive kampanjer og nye støttespillere",
        en: "I want to receive rare newsletters with exclusive campaigns and new supporters",
      },
    },
    submit: {
      no: "Få tilgang til OPRA",
      en: "Get access to OPRA",
    },
    errors: {
      generic: {
        title: {
          no: "Noe gikk galt",
          en: "Something went wrong",
        },
        message: {
          no: "Vennligst prøv igjen senere.",
          en: "Please try again later.",
        },
      },
    },
  },
});

type FormFields = {
  newOrExistingUser: "new" | "existing";
  email: string;
  password: string;
  acceptsTermsOfUse: boolean;
  acceptsMarketing: boolean;
  organization: string;
};

const loginUser = async ({
  email,
  password,
  acceptsMarketing,
  organization,
}: FormFields) => {
  try {
    const { user } = await signInWithEmailAndPassword(
      auth,
      email.toLowerCase(),
      password
    );
    if (!user) {
      return false;
    }
    const userRef = await getUser(user.uid);
    if (!userRef) {
      return false;
    }
    let updatedUser: Partial<OpraUser> = {
      subscriptionStatus: "ACTIVE",
      isOrganizationMember: true,
    };
    if (acceptsMarketing) {
      updatedUser.acceptsMarketing = acceptsMarketing;
    }
    if (userRef.subscriptionStatus !== "ACTIVE") {
      updatedUser.subscriptionStatus = "ACTIVE";
    }

    const updateUserPromise = updateDoc(
      doc(firestore, "users", user.uid),
      updatedUser
    );

    const isAlreadyMember = userRef.isOrganizationMember;

    const organizationCountPromise = isAlreadyMember
      ? Promise.resolve()
      : setDoc(
          doc(firestore, "organization", organization.toLowerCase()),
          {
            count: increment(1),
          },
          { merge: true }
        );

    await Promise.all([updateUserPromise, organizationCountPromise]);

    return user.uid;
  } catch (e: any) {
    if (e.code === "auth/user-not-found") {
      return false;
    }
    throw e;
  }
};
const createNewUser = async ({
  email,
  password,
  acceptsMarketing,
  organization,
}: FormFields) => {
  const { user } = await createUserWithEmailAndPassword(
    auth,
    email.toLowerCase(),
    password
  );
  if (!user) {
    return false;
  }
  const emailVerificationPromise = sendEmailVerification(user, {
    url: `${window.location.origin}/oversikt`,
  });
  const dbUser: OpraUser = {
    id: user.uid,
    email: email.toLowerCase(),
    subscriptionStatus: "ACTIVE",
    isOrganizationMember: true,
    favorites: [],
    userType: "regular",
    acceptsMarketing,
    currentCountry: "norway",
    preferredLanguage: "no",
    nudges: {},
    createdAt: new Date().getTime(),
  };

  const userCreationPromise = setDoc(doc(firestore, "users", user.uid), dbUser);

  const organizationCountPromise = setDoc(
    doc(firestore, "organization", organization.toLowerCase()),
    {
      count: increment(1),
    },
    { merge: true }
  );

  await Promise.all([
    emailVerificationPromise,
    userCreationPromise,
    organizationCountPromise,
  ]);

  return user.uid;
};
const loginOrCreateOrganizationUser = async (formFields: FormFields) => {
  let uid = await loginUser(formFields);
  if (!uid) {
    uid = await createNewUser(formFields);
  }
  return uid;
};

const getUser = async (userId: string) => {
  const userResult = await getDoc(doc(firestore, "users", userId));
  return userResult.data() as OpraUser | undefined;
};
