import {
  Button,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
} from "@chakra-ui/react";
import * as React from "react";
import { createTexts, useTranslation } from "utils/i18n";
import { Input } from "./Input";

type PasswordInputProps = InputProps;

/** A simple input for inputting passwords
 *
 * This input has a "show" / "hide" button to the right, which obfuscates
 * (or reveals) the password in clear text.
 *
 * It accepts all the props of a regular Input field.
 */
export const PasswordInput = React.forwardRef<
  HTMLInputElement,
  PasswordInputProps
>(function PasswordInput(props, ref) {
  const { isOpen: show, onToggle } = useDisclosure();
  const { t } = useTranslation();
  return (
    <InputGroup>
      <Input
        {...props}
        type={show ? "text" : "password"}
        ref={ref}
        paddingRight="4.5rem"
      />
      <InputRightElement
        width="4.5rem"
        justifyContent="flex-end"
        marginRight="0.5rem"
      >
        <Button
          size="sm"
          height="1.75rem"
          variant="solid"
          colorScheme="yellow"
          onClick={onToggle}
        >
          {show ? t(texts.hide) : t(texts.show)}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
});

const texts = createTexts({
  show: {
    no: "Vis",
    en: "Show",
  },
  hide: {
    no: "Skjul",
    en: "Hide",
  },
});
