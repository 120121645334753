import { ContentPage } from "features/content-page/ContentPage";
import { CustomSignupFlow } from "features/custom-signup-flow/CustomSignupFlow";
import { GetServerSideProps } from "next";
import { SanityImage } from "types/domain";
import { resolveLocalizedData } from "utils/locale";
import client from "utils/sanity";

type SanityContentPageProps =
  | {
      data: {
        title: string;
        excerpt: string;
        body: unknown;
        tags: string[];
        slugs: { no: string; en: string };
      };
      pageType: "contentPage";
    }
  | {
      data: {
        organizationName: string;
        shortenedOrganizationName?: string;
        slug: { current: string };
        logo: SanityImage;
        oneliner: string;
        content: unknown;
        acceptedDomains: string[];
      };
      pageType: "customSignupFlow";
    };
export default function SanityContentPage({
  data,
  pageType,
}: SanityContentPageProps) {
  if (pageType === "customSignupFlow") {
    return <CustomSignupFlow data={data} />;
  }
  return (
    <ContentPage
      title={data.title}
      excerpt={data.excerpt}
      tags={data.tags}
      content={data.body}
      slugs={data.slugs}
    />
  );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const slug = context.params?.slug as string;
  const locale = (context.locale as "no" | "en") ?? "no";
  const organizationData = await client.fetch(
    `*[_type == "customSignupFlow" && slug.${locale}.current == $slug][0] {
      organizationName,
      shortenedOrganizationName,
      slug,
      logo,
      oneliner,
      content,
      acceptedDomains
  }`,
    { slug }
  );
  if (organizationData) {
    const { referer } = context.req.headers;
    const isDevelopment = process.env.NODE_ENV === "development";
    const isAcceptedDomain =
      !Boolean(organizationData.acceptedDomains?.length) ||
      organizationData.acceptedDomains.some((domain: string) =>
        referer?.startsWith(domain)
      );
    if (!isAcceptedDomain && !isDevelopment) {
      return {
        notFound: true,
      };
    }

    return {
      props: {
        data: resolveLocalizedData(locale, organizationData),
        pageType: "customSignupFlow",
      },
    };
  }
  // Regular pages
  const data = await client.fetch(
    `*[_type == "contentPage" && slug.${locale}.current == $slug][0] {
    _type,
    title,
    slug {
      "no": no.current, 
      "en": "en/" + en.current,
    },
    excerpt,
    body,
    tags,
  }`,
    { slug }
  );

  if (!data) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      data: {
        ...resolveLocalizedData(locale, data),
        slugs: data.slug,
      },
    },
  };
};
