import { Box, BoxProps } from "@chakra-ui/react";
import theme from "utils/theme";
export const LogoSpear = (props: BoxProps) => {
  return (
    <Box
      as="svg"
      viewBox="20 0 80 80"
      width="100%"
      fill="none"
      aria-label="Logo, OPRA - Operativ rabatt"
      {...props}
    >
      <path
        d="M60 80a40 40 0 1 0 0-80 40 40 0 0 0 0 80Z"
        fill={theme.colors.brand.emergencyYellow}
      />
      <path
        d="M49 66.5c-.3-6.5-8-12.5-8-12.6l-2-1.6L60 9.8l21 42.5-2 1.6c-2.2 1.7-7.8 7.2-8 12.6l-6.3-.1c.2-7 5.4-13 8.4-15.8L60 24.2 46.9 50.6c2.9 2.8 8.2 8.8 8.4 15.8h-6.4Z"
        fill={theme.colors.brand.slateBlack}
      />
    </Box>
  );
};
